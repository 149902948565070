.rolling-container.bet {
	min-width: 516px !important;
}

.rolling-container.bet .balance-value {
	height: 33px !important;
}

.rolling-container.bet .bet-value {
	max-width: 163px;
	height: 33px !important;
}

.one-out-pt {
	left: 69% !important;
}

.two-outs-pt {
	left: 22% !important;
}

.zero-outs-bot-pt {
	left: 41% !important;
}

.zero-outs-top-pt {
	left: 41% !important;
}

.zero-outs-top-es {
	left: 35% !important;
}

.one-out-en {
	left: 67% !important;
}

.two-outs-en {
	left: 20% !important;
}

.zero-outs-bot-en {
	left: 39% !important;
}